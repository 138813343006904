import React, { useContext, useEffect } from "react"

import styled from "styled-components"
import { SRLWrapper } from "simple-react-lightbox"
import { RichText } from "prismic-reactjs"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import {
  Container,
  Tab,
  Nav,
  Row,
  Col,
  ListGroup,
  Accordion,
  Card,
} from "react-bootstrap"
import { graphql, Link } from "gatsby"
import { useLocation } from "@reach/router"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import GlobalContext from "../contexts/GlobalContext"
import { allLangs } from "../utils/configClient"
import { slugHome } from "../utils/slugs"

import { Box } from "../components/Core"
import PageWrapper from "../components/PageWrapper"
import Hero from "../sections/common/Hero"
import Support from "../sections/common/Support"
import Contact from "../sections/common/Contact"
import { Section, Title, Text } from "../components/Core"

import ProductAccordion from "../components/ProductAccordion"
import ProductSlider from "../components/ProductSlider"
import bgImg from "../assets/images/bg3.jpg"

const AccordionStyled = styled(Accordion)`
  .card-header {
    cursor: pointer;
    font-weight: bold;
  }
  ul {
    margin-bottom: 0;
  }
`

const TechOverview = ({ data }) => {
  const gContext = useContext(GlobalContext)

  useEffect(() => {
    if (gContext.currentLang !== allLangs.hk) {
      gContext.setCurrentLang(allLangs.hk)
    }
  }, [])

  const { pathname } = useLocation()

  const techData = data.prismicTechnicalOverview?.data

  const { btn_back, btn_home, btn_request_sample } = data.prismicCommon?.data

  return (
    <>
      <GatsbySeo
        title={techData.meta_title?.text}
        description={techData.meta_description?.text}
      />
      <PageWrapper>
        <Hero bgImg={techData?.cover_image.url || bgImg}>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                {techData?.title && (
                  <Title variant="hero" color="light">
                    {techData?.title.text}
                  </Title>
                )}

                <Box mt={4}>
                  <div className="d-flex my-3 justify-content-center">
                    <div className="text-white font-weight-bold">
                      {">>"}
                      <Link
                        to={slugHome(gContext.currentLang)}
                        className="text-white font-weight-bold"
                      >
                        {btn_home ? btn_home.text : "Home"}
                      </Link>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Container>
        </Hero>
        <Section
          pt={["45px !important", null, null, "60px !important"]}
          className="pb-5"
        >
          <Container fluid>
            <Row className="justify-content-center">
              <Col lg="9" className="pb-5 pb-lg-0">
                <Tab.Container defaultActiveKey="info">
                  <Row>
                    <Col md={3} className="mb-5 mb-lg-0">
                      <>
                        <div className="d-flex my-3 justify-content-start">
                          <div className="text-white font-weight-bold ml-2">
                            <Link
                              to={slugHome(gContext.currentLang)}
                              className="text-dark font-weight-bold"
                            >
                              {"<<"} {btn_back ? btn_back.text : "Back"}
                            </Link>
                          </div>
                        </div>
                      </>
                      <ListGroup
                        css={`
                          .list-group-flush:first-child,
                          .list-group-item:first-child {
                            border-top: 1px solid rgba(24, 59, 86, 0.115);
                          }
                          .active.nav-link {
                            color: white !important;
                          }
                        `}
                      >
                        <ListGroup.Item as={Nav.Link} eventKey="info">
                          {techData?.title.text}
                        </ListGroup.Item>

                        <ListGroup.Item
                          as={AnchorLink}
                          to={`${pathname}#support`}
                          stripHash
                        >
                          {btn_request_sample
                            ? btn_request_sample.text
                            : "Request Sample"}
                        </ListGroup.Item>
                      </ListGroup>
                    </Col>
                    <Col md={9}>
                      <Box mb={4}>
                        <div className="d-flex my-3 justify-content-left">
                          <div className="text-primary font-weight-bold">
                            {">>"}
                            <Link
                              to={slugHome(gContext.currentLang)}
                              className="text-primary font-weight-bold"
                            >
                              {btn_home ? btn_home.text : "Home"}
                            </Link>
                          </div>
                          {techData?.title && (
                            <div className="text-dark font-weight-bold ml-2">
                              <div>
                                {" >> "}
                                {techData?.title.text}
                              </div>
                            </div>
                          )}
                        </div>
                      </Box>
                      <Tab.Content>
                        <Tab.Pane eventKey="info">
                          <Box className="" pb={4}>
                            <AccordionStyled defaultActiveKey={1}>
                              {techData?.accordion.map((item, index) => (
                                <Card key={index}>
                                  <Accordion.Toggle
                                    as={Card.Header}
                                    eventKey={index + 1}
                                  >
                                    {item.title1 && item.title1.text}
                                  </Accordion.Toggle>
                                  <Accordion.Collapse eventKey={index + 1}>
                                    <Card.Body>
                                      <SRLWrapper>
                                        <div className="rich-content">
                                          {RichText.render(item.content.raw)}
                                        </div>
                                      </SRLWrapper>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              ))}
                            </AccordionStyled>
                          </Box>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Col>
              <Col lg="3">
                <ProductAccordion />
                <ProductSlider />
              </Col>
            </Row>
          </Container>
        </Section>
        <div id="support">
          <Support />
        </div>
        <div id="contact">
          <Contact />
        </div>
      </PageWrapper>
    </>
  )
}
export default TechOverview

export const query = graphql`
  query($lang: String!) {
    prismicCommon(lang: { eq: $lang }) {
      data {
        btn_back {
          text
        }
        btn_home {
          text
        }
        btn_request_sample {
          text
        }
      }
    }

    prismicTechnicalOverview(lang: { eq: $lang }) {
      data {
        title {
          text
        }
        cover_image {
          url
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        accordion {
          title1 {
            text
          }
          content {
            raw
          }
        }
      }
    }
  }
`
